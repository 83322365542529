import { connectStats } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";

const Stats = ({ nbHits, query, lang }) => {
  const { t } = useTranslation();
  return (
    <p className="CustomStats-text">
      
      {nbHits !== 0 ? (
        <>
          {t("results")} {nbHits}{" "}
        </>
      ) : (
        <> {t("resultsNo")}</>
      )}
    </p>
  );
};

const CustomStats = connectStats(Stats);
export default CustomStats;
