import React from "react";
import { useTranslation } from "react-i18next";

const NeqatyCard = (props) => {
  const { t } = useTranslation();
  const win = window;
  const serverURL = win["base_portal_url"]
    ? win["base_portal_url"]
    : window.location.origin;
  const onCardClick = () => {
    window.location.href = serverURL + props.data.pageUrl;
  };
  return (
    <div className="neqatyCard">
      <div className="neqatyTitle">{props.data.title}</div>

      <div className="neqatyContent">
        <div className="neqatyDesc">{props.data.desc}</div>
      </div>
      <div className="neqatyFooter">
        <a className="neqatyBtn" href={serverURL + props.data.pageUrl}>
          {t("selectBtn")}
        </a>
      </div>
    </div>
  );
};

export default NeqatyCard;
