import { connectCurrentRefinements } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";

const ClearRefinements = ({ items, refine }) => {
  function refineAll(items) {
    
    refine(items);
    document.getElementById("clear-refinements").style.display = "none";
  }
  const { t } = useTranslation();
  return (
    <button
      className="clear-refinements"
      id="clear-refinements"
      onClick={() => refineAll(items)}
      disabled={!items.length}
    >
      {t("clearAll")}
    </button>
  );
};

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

export default CustomClearRefinements;
