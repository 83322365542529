// import { Card } from "antd";
import { useTranslation } from "react-i18next";
const PlanCard = (props) => {
  //console.log(props);
  const win = window;
  const serverURL = win["base_portal_url"]
    ? win["base_portal_url"]
    : window.location.origin;

  const onCardClick = () => {
    window.location.href = serverURL + props.data.pageUrl;
  };
  const { t } = useTranslation();
  return (

      <div style={{ cursor: "pointer" }} onClick={onCardClick}>
        <div className="planInfo" style={{
          background:
            "linear-gradient(270deg, rgba(2, 38, 68, 0) -2%, rgba(1, 38, 68, 0.8) 100%), url(" +
            serverURL +
            props.data.image +
            ") center/cover no-repeat ",
        }}>
          {/* <div>
            {props.data.image ? (
              <img
                style={{
                  height: "360px",
                  borderRadius: "10px",
                  maxWidth: "220px",
                }}
                width="220px"
                src={serverURL + props.data.image}
                alt={props.data.title}
              />
            ) : null}
          </div> */}
          <div className="planDetails">
            <div className="planTitle">{props.data.title}</div>
            <div className="planBenifits">
              {props.data.benifits.data ? (
                <>
                  <div className="planBenifitTitle">{t("planData")}</div>
                  <div className="planBenifitDetail">
                    {props.data.benifits.data} {t("dataGB")}
                  </div>
                </>
              ) : null}
              {props.data.benifits.sms ? (
                <>
                  <div className="planBenifitTitle">{t("SMS")}</div>
                  <div className="planBenifitDetail">
                    {props.data.benifits.sms}
                  </div>
                </>
              ) : null}
              {props.data.benifits.dataVolume ? (
                <>
                  <div className="planBenifitTitle">{t("planData")}</div>
                  <div className="planBenifitDetail">
                    {props.data.benifits.dataVolume} {t("dataGB")}
                  </div>
                </>
              ) : null}
              {props.data.benifits.serviceCommitment ? (
                <>
                  <div className="planBenifitTitle">
                    {t("serviceCommitment")}
                  </div>
                  <div className="planBenifitDetail">
                    {props.data.benifits.serviceCommitment}
                  </div>
                </>
              ) : null}
              {props.data.benifits.minutes ? (
                <>
                  <div className="planBenifitTitle">{t("planMinutes")}</div>
                  <div className="planBenifitDetail">
                    {props.data.benifits.minutes}
                  </div>
                </>
              ) : null}
              {props.data.benifits.socialMedia ? (
                <>
                  <div className="planBenifitTitle">{t("planSocialMedia")}</div>
                  <div className="planBenifitDetail">
                    {props.data.benifits.socialMedia}
                  </div>
                </>
              ) : null}
              {props.data.benifits.downloadSpeed ? (
                props.data.authoringTemplateId !== "AT_plan_5g" ? (
                  <>
                    <div className="planBenifitTitle">{t("downloadSpeed")}</div>
                    <div className="planBenifitDetail">
                      {props.data.benifits.downloadSpeed} {t("dataMbps")}
                    </div>
                  </>
                ) : null
              ) : null}
              {props.data.benifits.uploadSpeed ? (
                props.data.authoringTemplateId !== "AT_plan_5g" ? (
                  <>
                    <div className="planBenifitTitle">{t("uploadSpeed")}</div>
                    <div className="planBenifitDetail">
                      {props.data.benifits.uploadSpeed} {t("dataMbps")}
                    </div>
                  </>
                ) : null
              ) : null}
            </div>
          </div>
          {props.data.Price ? (
            <div className="planPrice">
              {props.data.Price}{" "}
              <span className="priceUnit">
                {t("SAR")}/{props.data.benifits.duration}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    
  );
};
export default PlanCard;
