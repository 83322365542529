import { Card } from "antd";
import { useTranslation } from "react-i18next";
const DeviceCard = (props) => {
  //console.log(props);
  const win = window;
  const serverURL = win["base_portal_url"]
    ? win["base_portal_url"]
    : window.location.origin;

  const onCardClick = () => {
    window.location.href = serverURL + props.data.pageUrl;
  };
  const { t } = useTranslation();
  return (
    <Card bordered={false} className="deviceCard productCard">
      <div>
        <img
          style={{ height: "220px", maxWidth: "120px" }}
          src={serverURL + props.data.image}
          alt={props.data.title}
        />
        <div
          className="deviceDescription"
          style={{ cursor: "pointer" }}
          onClick={onCardClick}
        >
          <div className="deviceTitle">{props.data.title}</div>
          <div className="devicePrice">
            {t("priceStart")} {props.data.Price}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default DeviceCard;
