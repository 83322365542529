import React, { useState } from "react";
import { connectRange } from "react-instantsearch-dom";

// Prerequisite: install rheostat@4
import "rheostat/initialize";
import Rheostat from "rheostat";

import { useTranslation } from "react-i18next";
import AutoDirectionProvider from "react-with-direction/dist/AutoDirectionProvider";

import { getPageLangauge } from "../../util/HelperFunctions";
const RangeSlider = ({
  min,
  max,
  currentRefinement,
  canRefine,
  refine,
  idCustom,
  title,
}) => {
  // translation
  const { t } = useTranslation();

  const [stateMin, setStateMin] = React.useState(min);
  const [stateMax, setStateMax] = React.useState(max);

  React.useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    } else {
    }
  }, [currentRefinement.min, currentRefinement.max]);
  if (min === max) {
    return null;
  }

  const onChange = ({ values: [min, max] }) => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min, max });
    }
  };

  const onValuesUpdated = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
  };

  const appStyle_low_handle = {
    direction: getPageLangauge() === "ar" ? "rtl" : "ltr",
    transform:
      getPageLangauge() === "ar" ? "translateX(-55%)" : "translateX(-35%)",
  };
  const appStyle_high_handle = {
    direction: getPageLangauge() === "ar" ? "rtl" : "ltr",
    transform:
      getPageLangauge() === "ar" ? "translateX(-80%)" : "translateX(-85%)",
  };
  //
  return (
    <div className="filter-box">
      <div className="all-price">
        <div className="range-slider" id={title}>
          <h3 className="filter-header">{t(title)}</h3>
          <div className="priceContainer">
            <div className="rheostat-value1">{stateMin}</div>
            <p> - </p>
            <div className="rheostat-value1">
              {stateMax} {t("SAR")}
            </div>
          </div>

          <AutoDirectionProvider text="gh">
            <Rheostat
              max={max}
              min={min}
              values={[currentRefinement.min, currentRefinement.max]}
              onChange={onChange}
              onValuesUpdated={onValuesUpdated}
            >
              <div
                className="rheostat-marker rheostat-marker--large"
                style={{ left: 0 }}
              >
                <div className="rheostat-value" style={appStyle_low_handle}>
                  {stateMin} {t("SAR")}
                </div>
              </div>
              <div
                className="rheostat-marker rheostat-marker--large"
                style={{ right: 0 }}
              >
                <div className="rheostat-value" style={appStyle_high_handle}>
                  {stateMax} {t("SAR")}
                </div>
              </div>
            </Rheostat>
          </AutoDirectionProvider>
        </div>
      </div>
    </div>
  );
};

const CustomRangeSlider = connectRange(RangeSlider);
export default CustomRangeSlider;
