import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ENtrans from "./Language/en.json";
import ARtrans from "./Language/ar.json";
import { getPageLangauge } from "./util/HelperFunctions";

let lng = getPageLangauge();

i18n.use(initReactI18next).init({
  lng,
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: "en",
  resources: {
    en: { translation: ENtrans },
    ar: { translation: ARtrans },
  },
  react: {
    wait: true,
    useSuspense: true,
  },
});

export default i18n;
