import { connectSearchBox } from "react-instantsearch-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

const SearchBox = ({ currentRefinement, refine }) => {
  const { t } = useTranslation();

  function searchIconStyle() {
    let style = {
      transform:
        document.documentElement.lang === "ar"
          ? "translateX(52%) translateY(15%)"
          : "translateX(-52%) translateY(15%)",
    };

    return style;
  }

  return (
    <div className="searchBoxOutter">
      <div className="searchBoxRow">
        <input
          autoFocus
          className="searchBoxFeild search-inputField"
          type="search"
          placeholder={t("searchPLaceHolder")}
          id="SearchBoxInput"
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
        />
        <>
          <>
            {currentRefinement.length !== 0 ? (
              <FontAwesomeIcon
                icon={faXmark}
                className="searchBoxClose"
                onClick={() => {
                  refine("");
                }}
                style={searchIconStyle()}
              />
            ) : (
              <FontAwesomeIcon
                className="searchBoxIcon"
                icon={faMagnifyingGlass}
                style={searchIconStyle()}
              />
            )}
          </>
        </>
      </div>
    </div>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);
export default CustomSearchBox;
