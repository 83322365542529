import React from "react";

export const HelperFunctions = () => {
  return <div>HelperFunctions</div>;
};

export const getPageLangauge = () => {
  const allowedLang = ["ar", "en"];
  let lang = document.documentElement.lang;
  if (!allowedLang.includes(lang.toLowerCase())) return "en"; // fallback language

  return lang;
};

export function searchText() {
  const queryParams = new URLSearchParams(window.location.search);
  var defaultRefinementText = queryParams.get("searchtext");

  return defaultRefinementText;
}
