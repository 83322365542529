import React from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { Row, Col } from "antd/lib/grid";
import DeviceCard from "../types/DeviceCard";
import PlanCard from "../types/PlanCard";
import NeqatyCard from "../types/NeqatyCard";

class InfiniteHits extends React.PureComponent {
  static defaultProps = {
    renderSearchBox() {
      return null;
    },
  };

  constructor(props) {
    super(props);
    this.sentinel = null;
    this.onSentinelIntersection = this.onSentinelIntersection.bind(this);
  }

  componentDidMount() {
    this.intersectionObserver = new IntersectionObserver(
      this.onSentinelIntersection
    );

    this.intersectionObserver.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.intersectionObserver.disconnect();
  }

  onSentinelIntersection(entries) {
    const { hasMore, refineNext } = this.props;

    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  }

  render() {
    const { hits } = this.props;
    const colXS = 12;
    const colSM = 8;
    const colMD = 8;
    const colLG = 8;
    const colXL = 6;
    return (
      <React.Fragment>
        <ul className="hits">
          <Row gutter={[14, 16]}>
            {hits.map((hit) => {
              if (hit.tempView === "DeviceCard") {
                return (
                  <Col xs={colXS} sm={colSM} md={colMD} lg={colLG} xl={colXL}>
                    <DeviceCard data={hit} />
                  </Col>
                );
              } else if (hit.tempView === "PlanCard") {
                return (
                  <Col xs={colXS} sm={colSM} md={colMD} lg={colLG} xl={colXL}>
                    <PlanCard data={hit} />
                  </Col>
                );
              } else if (hit.tempView === "SelectCard") {
                return (
                  <Col xs={colXS} sm={colSM} md={colMD} lg={colLG} xl={colXL}>
                    <NeqatyCard data={hit} />
                  </Col>
                );
              } else {
                return <></>
              }
            })}
          </Row>
        </ul>
        <div
          ref={(c) => {
            this.sentinel = c;
          }}
        />
      </React.Fragment>
    );
  }
}
const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);
export default CustomInfiniteHits;
