import React from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { t } from "i18next";

function visib(items) {
  items !== null
    ? (document.getElementById("clear-refinements").style.display = "block")
    : (document.getElementById("clear-refinements").style.display = "none");
}
const CurrentRefinements = ({
  items,
  refine,
  createURL,
  currentRefinement,
}) => {

  const labelTag = (item) => {
    let label = item.label;
    try {
     
      let min = document
        .getElementsByClassName("handleContainer")[0]
        .firstChild.getAttribute("aria-valuenow");
      let max = document
        .getElementsByClassName("handleContainer")[0]
        .lastChild.getAttribute("aria-valuenow");
      label = t("filterPrice") +" " + min + " - " + max;
    } catch (error) {
      
    }
   
    return label;
  };
  return (
    <ul className="current-refinements">
      {items.map((item) => (
        <div>
          <li key={item.label}>
            {item.items ? (
              <React.Fragment>
                <ul>
                  {item.items.map((nested) => (
                    <li
                      key={nested.label}
                      className="current-refinements-items"
                    >
                      {" "}
                      <a
                        href={createURL(nested.value)}
                        onClick={(event) => {
                          event.preventDefault();
                          refine(nested.value);
                         
                          // visib(items);
                        }}
                      >
                        {nested.label}{" "}
                      <FontAwesomeIcon icon={faXmark} className="close-refinement-icon" />
                      </a>{" "}
                    </li>
                  ))}
                  {/* <CustomClearRefinements /> */}
                </ul>
              </React.Fragment>
            ) : (
              <a
                className="currenet-refinement-price-label"
                href={createURL(item.value)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(item.value);
                  // document
                  //   .getElementById("CustomHits")
                  //   .setAttribute("query", item.value);
                }}
              >
                {/* {item.label.split(".")[1] ?? item.label} */}
                {labelTag(item)}
                {/* {item.label} */}
                {}
                <FontAwesomeIcon icon={faXmark} className="close-refinement-icon" />
              </a>
            )}
          </li>
          {/* {items !==null? <CustomClearRefinements  /> : null} */}
          {visib(items)}
        </div>
      ))}
    </ul>
  );
};

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);
export default CustomCurrentRefinements;
