import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import HomePage from "./components/HomePage";
import { useState } from 'react';

import "./local";
import "./App.css";
import { getPageLangauge } from "./util/HelperFunctions";

const algoliaClient = algoliasearch(
  "A47E3BWDPH",
  "e5c93dfd73b094a9553e9a07349b774e"
);

const searchClient = {
  search(requests) {
    const newRequests = requests.map((request) => {
      // test for empty string and change request parameter: analytics
      if (!request.params.query || request.params.query.length === 0) {
        request.params.analytics = false;
      }
      return request;
    });
    return algoliaClient.search(newRequests);
  },
};

const appStyle = {
  direction: getPageLangauge() === "ar" ? "rtl" : "ltr",
};

const searchFlagInitialState = window.location.href.includes("localhost") ? true : false

function App() {
  const [searchFlag, setSearchFlag] = useState(searchFlagInitialState)

  const mobilySearchOn = () => {
    setSearchFlag(true)
  }

  const mobilySearchOff = () => {
    setSearchFlag(false)
  }

  window.handleMobilySearchOn = mobilySearchOn;
  window.handleMobilySearchOff = mobilySearchOff;

  console.log('---root search---');
  console.log('Mobily Search', searchFlag);

  return (
    <>
      {searchFlag === true ? (
        <div className="search-app-container top-mobily" id="App" style={appStyle}>
          <InstantSearch
            searchClient={searchClient}
            indexName={"Prod_WCM_SEARCH_" + getPageLangauge().toUpperCase()}
          >
            <Configure hitsPerPage={32} filters="(tempView:PlanCard OR tempView:SelectCard OR tempView:DeviceCard) AND platform:web"/>
            <HomePage />
          </InstantSearch>
        </div>
      ) : (
        null
      )}
    </>
  );
}

export default App;
