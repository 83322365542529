import React from "react";
import { RefinementList } from "react-instantsearch-dom";
import CustomStats from "./Stats/Stats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import CustomRangeSlider from "./RangeSlider/RangeSlider";
import CustomCurrentRefinements from "./CurrentRefinement/CurrentRefinements";
import CustomClearRefinements from "./CurrentRefinement/ClearRefinements";
import CustomSearchBox from "./SearchBox/SearchBox.js";
import { useTranslation } from "react-i18next";
import CustomInfiniteHits from "./InfiniteHits/InfiniteHits";

const HomePage = (currentRefinement, defaultRefinement) => {
  const { t } = useTranslation();
  function filterButton() {
    var status = document.getElementById("left-panel-xl").style.display;
 
    document.getElementById("left-panel-xl").style.display =
      status !== "block" ? "block" : "none";
    document.getElementById("filterShow").style.display =
      status !== "block" ? "none" : "block";
    document.getElementById("filterHide").style.display =
      status !== "block" ? "block" : "none";
  }
  
  return (
    <div className="MainPage">
      <CustomSearchBox/>
      <div className="search-homePage">
        <button className="filterButtonSmallScreeen" onClick={filterButton}>
          {t("filterList")}{" "}
          <div className="filterIcons">
            {" "}
            <FontAwesomeIcon icon={faFilter} />
            <FontAwesomeIcon id="filterShow" icon={faPlus} />
            <FontAwesomeIcon
              id="filterHide"
              icon={faMinus}
              style={{ display: "none" }}
            />
          </div>
        </button>
        <div className="left-panel left-panel-xl" id="left-panel-xl">
          <div className="big-left-filter-box">
            <i class="las la-battery-three-quarters"></i>
            <CustomRangeSlider
              attribute="Price"
              title="filterPrice"
              idCustom="Device"
              id="Device"
            />{" "}
           
            <div id="range-slider"></div>
      
            <div className="menu">
              <h3 class="filter-header">{t("filterList")}</h3>

     
              <RefinementList
                attribute="parentTitle"
                translations={{
                  showMore(expanded, items) {
                   
                    return expanded ? (
                      <div className="menuButton">
                        <p>{t("seeLess")} </p>

                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="menuLessButton menu-icon"
                        />
                      </div>
                    ) : (
                      <div className="menuButton">
                        <p>{t("seeMore")} </p>{" "}
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="menu-icon"
                        />
                      </div>
                    );
                  },
                }}
             
                showMore={true}
              />
            </div>
          </div>
        </div>
        <div className="right-panel">
          {/* seperate it stats from hits, so I can put current refinements in the middle */}
          <div className="current-refinement-panel">
            <CustomCurrentRefinements />
            <CustomClearRefinements />{" "}
          </div>
          {currentRefinement !== "" ? (
            <>
              <CustomStats query={currentRefinement} className="CustomStats" />
            </>
          ) : null}
          {currentRefinement !== "" ? (
            <>
              <span>
                <CustomInfiniteHits />
                &nbsp;
              </span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
