class localhostSettings { }

const win = window;
if (window.location.href.includes("localhost")) {
    win["lang"] = "en";
    document.documentElement.lang = win["lang"];
    win["base_portal_url"] = "http://ssta004:8080";
    win["base_api_url"] = "http://10.14.193.76:9003/api";
}

export default localhostSettings;